import "./TermsAndConditions.css";
import React from "react";
import AppHeader from "../components/AppHeader";
import { WhiteTextNormalTypography, WhiteTextBoldTypography, BlackTextNormalTypography, BlackTextBoldTypography, RouterButton } from "../components/StyledMUIComponents";
import { Link } from "react-router-dom";

export default class TermsAndConditions extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <AppHeader />
                <div className="TermsAndConditions">
                    <div className="TermsAndConditionsBlock">
                        <div className="TextWrapper">
                            <WhiteTextBoldTypography variant="h5">ARTIKEL 1: SCOPE</WhiteTextBoldTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">Elke festivaldeelnemer (hierna “festivalganger”) verklaart deze voorwaarden te hebben gelezen en ermee akkoord te gaan alvorens de aankoop van zijn ticket af te ronden.</WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextBoldTypography variant="h5">ARTIKEL 2: TICKETS</WhiteTextBoldTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">Enkel tickets of e-tickets die worden toegezonden per email na betaling van hetzij 12,- per festivalganger voor een ééndagsticket, hetzij 20,- per festivalganger voor een tweedaags ticket op de rekening van Group Daedalus bv geven toegang tot het evenement Half Oogst Midsummer Music 2022.
                            Het ticket (fysiek of e-ticket) geeft in geen geval toegang tot een vaste/gereserveerde plaats op het festival of de camping.
                            Het ticket (fysiek of e-ticket) dient bij de ingang van het festival te worden omgewisseld voor een polsbandje.
                            Het ticket (fysiek of e-ticket) worden in geen geval vervangen of terugbetaald.
                            In geval van annulering of uitstel van het evenement heeft u recht op terugbetaling, of kan u uw ticket omruilen voor een gelijkwaardig ticket voor een volgende jaargang.
                            Elke vervalsing van tickets (fysiek of e-ticket) of polsbandjes zal worden vervolgd.
                            Kinderen zijn meer dan welkom op het festival. Sterker nog: ze hebben gratis toegang tot 12 jaar.
                            Elk ticket is slechts één keer geldig. Een ticket geeft alleen toegang aan de persoon wiens naam op het ticket vermeld staat. De persoon die de aankoop heeft gedaan is verantwoordelijk voor het gebruik van het ticket.
                            Group Daedalus bv wijst alle verantwoordelijkheid af in geval van verlies, diefstal of misbruik van een ticket.. Het is ten strengste verboden om foto’s te nemen, te filmen of geluidsopnames te maken tijdens de optredens met professionele apparatuur, tenzij uitdrukkelijk overeengekomen met de organisatie.
                        </WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextBoldTypography variant="h5">ARTIKEL 3: VERBODEN VOORWERPEN</WhiteTextBoldTypography>
                        </div>

                        <WhiteTextNormalTypography variant="body1">•	alle soorten voedsel</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	alcohol, plastic flessen (water, melk, …), glazen flessen of andere glazen recipiënten</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	metalen kratten (blikken), kartons (tetra pak), metalen blikken, vaatjes, waterzakken (CamelBak), koelers</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	spuitbussen</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	ongeoorloofde middelen. Het is ten strengste verboden drugs te gebruiken of te verkopen op het festival. Elke overtreding kan leiden tot uitsluiting van het festival</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	wapens, scherpe voorwerpen (zelfs nep), puntige paraplu’s</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	vuurwerk, bengaals vuur, barbecues, …</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	grote tassen en te grote rugzakken</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	opnameapparatuur (behalve voor smartphones), luidsprekers, megafoons, selfie-sticks, laserpointers</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	markers, verfbommen</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	de verspreiding van flyers, folders, reclameposters (behalve met toestemming)</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	affiches over culturele evenementen mogen ALLEEN aan de daarvoor bestemde hekken worden opgehangen</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	dieren (met uitzondering van geleidehonden)</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">•	drones</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">Alles wat door de organisatie als gevaarlijk wordt beschouwd.
                            Deze lijst kan te allen tijde door de organisatie worden aangepast of aangevuld.
                            Elke niet-toegelaten verkoop is formeel verboden. Alle in beslag genomen goederen worden ter plaatse vernietigd.</WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextBoldTypography variant="h5">ARTIKEL 4: VEILIGHEID</WhiteTextBoldTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">Om veiligheidsredenen kan de organisatie een fouillering uitvoeren aan de ingang van het festival of de camping.
                            Iedere festivalganger die zich niet aan deze formaliteit houdt, zal de toegang tot het festival en de camping worden ontzegd.
                            De festivalganger volgt te allen tijde de aanwijzingen van het beveiligingspersoneel op. Indien een festivalganger verdacht gedrag waarneemt, wordt hij aangeraden het beveiligingspersoneel op het festival of de camping hiervan op de hoogte te brengen.
                            De organisatie heeft het recht de toegang tot het festival of de camping te ontzeggen indien bepaald gedrag gevaarlijk wordt geacht voor de veiligheid van de festivalbezoekers.
                        </WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextBoldTypography variant="h5">ARTIKEL 5: VERKOOP OP HET FESTIVALTERREIN</WhiteTextBoldTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">Het is verboden voor de festivalganger om iets te verkopen.
                            De verkoop van voedsel, drank, souvenirs, T-shirts of andere zaken is voorbehouden aan erkende verkopers (fysiek of moreel).
                        </WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextBoldTypography variant="h5">ARTIKEL 6: VERANTWOORDELIJKHEID</WhiteTextBoldTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">De organisatie kan in geen geval aansprakelijk worden gesteld – zelfs niet in geval van overmacht voor schade aan goederen of personen.
                            De organisatie kan bijvoorbeeld niet aansprakelijk gesteld worden voor schade aan auto’s die op het festival geparkeerd staan of aan de parkeerplaatsen van het festival.
                            De organisatie kan in geen geval verplicht worden een vergoeding of compensatie van welke aard ook aan een festivalganger of een derde aan te bieden.
                        </WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextBoldTypography variant="h5">ARTIKEL 7: BEELDRECHTEN</WhiteTextBoldTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">Door deel te nemen aan het festival, staat de festivalganger de beeldrechten van op het festival of de camping gemaakte beelden af aan de organisatie. In het geval er beeldmateriaal van de festivalganger op het festival of de camping wordt gemaakt, of de beelden via de communicatiekanalen van het festival (print of digitaal) worden verspreid, ziet de festivalganger af van elk verhaal tegen de organisatie.</WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextBoldTypography variant="h5">ARTIKEL 8: ONAFHANKELIJKHEID VAN CLAUSULES</WhiteTextBoldTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">Indien de organisatie besluit een of meer clausules van deze voorwaarden niet af te dwingen, kan dit niet worden geïnterpreteerd als een verklaring van afstand van deze voorwaarden.
                            In geval van nietigheid van één of meer clausules van deze voorwaarden, blijven de andere clausules onaangetast.
                        </WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextBoldTypography variant="h5">ARTIKEL 9: BEVOEGDHEID EN TOEPASSELIJK RECHT</WhiteTextBoldTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">In geval van betwisting zijn enkel de rechtbanken van Mechelen bevoegd.
                            Alleen het Belgisch recht is van toepassing.
                        </WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextNormalTypography variant="h6">Data en plaats</WhiteTextNormalTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">14 en 15 aug 2022</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">Het terrein is toegankelijk deze twee dagen tussen 12 u en 02 u s’nachts de dag daarop</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">Bosstraat 35 (achter garage Wuyts)</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">2861 O.L.V.Waver</WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextNormalTypography variant="h6">Group Daedalus bv</WhiteTextNormalTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">BTW nr : BE 0565 936 008</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">Bank Crelan : BE42 1030 3621 8454</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">BIC NICABEBB</WhiteTextNormalTypography>
                        <div className="TextWrapper">
                            <WhiteTextNormalTypography variant="h6">Alle info en vragen</WhiteTextNormalTypography>
                        </div>
                        <a href="mailto:info@halfoogstfestival.be">info@halfoogstfestival.be</a>
                        <WhiteTextNormalTypography variant="body1"></WhiteTextNormalTypography>
                        <a href="tel:0032496245765">0032/496.24.57.65</a>
                        <div className="TextWrapper">
                            <WhiteTextNormalTypography variant="h6">Eindverantwoordelijke</WhiteTextNormalTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">Ivo Van Itterbeeck</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">Bosstraat 35</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">2861 O.L.V.Waver</WhiteTextNormalTypography>
                    </div>
                    <Link to="/" className="RouterButton">
                        <RouterButton variant="contained">Homepage</RouterButton>
                    </Link>
                </div>
            </div>
        );
    }
}
