import "./ZeroStress.css";
import React from "react";
import AppHeader from "../components/AppHeader";
import { WhiteTextNormalTypography, WhiteTextBoldTypography, BlackTextNormalTypography, BlackTextBoldTypography, RouterButton } from "../components/StyledMUIComponents";
import { Link } from "react-router-dom";

export default class ZeroStress extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <AppHeader />
                <div className="ZeroStress">
                    <div className="ZeroStressBlock">
                        <div className="TextWrapper">
                            <WhiteTextBoldTypography variant="h5">Zero-Stress arrangement</WhiteTextBoldTypography>
                        </div>
                        <WhiteTextNormalTypography variant="body1">• Je krijgt toegang tot de verhoogde kijkzone van de VIP-area waar een barkruk aan sta-tafeltje voor jou gereserveerd staat (5 personen per tafel)</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">• Toegang tot afgesloten chill-out area met ligzeteltjes, petanquebaan, teppeschiet- en kubb-veld</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">• Aparte toog</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">• Welkomstdrankje (glas cava / fles per 5 personen) en kleine barhapjes</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">• All-in luxe BBQ (tussen 18u en 20u)</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">• Je kan natuurlijk ook altijd naar het podium gaan om te dansen, zingen, ... zo vaak je wil</WhiteTextNormalTypography>
                        <br></br>
                        <WhiteTextNormalTypography variant="h5">Kostprijs:</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body2">(toegang festival ; toegang VIP-ruimte ; welkomstdrankje ; luxe-BBQ)</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="h5">€50,- per persoon</WhiteTextNormalTypography>
                        <WhiteTextNormalTypography variant="body1">(of €240,- voor een statafel van 5 personen)</WhiteTextNormalTypography>
                        <br></br>
                        <WhiteTextNormalTypography variant="body1">Opgelet: Beperkt aanbod</WhiteTextNormalTypography>
                        <br></br>
                        <WhiteTextNormalTypography variant="h5">Interesse? Stuur een mailtje naar: <a href="mailto:info@halfoogstfestival.be">info@halfoogstfestival.be</a></WhiteTextNormalTypography>
                    </div>
                    <Link to="/" className="RouterButton">
                        <RouterButton variant="contained">Homepage</RouterButton>
                    </Link>
                </div>
            </div >
        );
    }
}
