import "./Lineup.css";
import React from "react";
import AppHeader from "../components/AppHeader";
import { WhiteTextNormalTypography, WhiteTextBoldTypography, BlackTextNormalTypography, BlackTextBoldTypography, RouterButton, ColoredLine } from "../components/StyledMUIComponents";
import { Link } from "react-router-dom";

export default class Lineup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <AppHeader />
        <div className="Lineup">
          <div className="LineupDay">
            <div className="TextWrapper">
              <WhiteTextNormalTypography variant="h4">Zondag 14 augustus</WhiteTextNormalTypography>
            </div>
            <div className="LineupBands">
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h6">Blues Syndicate</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h8">13:15</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="body1">David Ronaldo's</WhiteTextBoldTypography>
                <WhiteTextBoldTypography variant="h5">The Black Sneakers</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h7">15:05</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="body1">tribute to</WhiteTextBoldTypography>
                <WhiteTextBoldTypography variant="h4">Joe Cocker</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h6">16:55</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h6">C4</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h8">18:45</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h5">Moots</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h7">20:35</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h4">Band Marginal</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h6">22:25</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h6">'015KID' Music Mix</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h8">00:00 - 02:00</WhiteTextNormalTypography>
              </div>
            </div>
          </div>
          <div className="LineupDay">
            <div className="TextWrapper">
              <WhiteTextNormalTypography variant="h4">Maandag 15 augustus</WhiteTextNormalTypography>
            </div>
            <div className="LineupBands">
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h6">GUTS</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h8">13:15</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h5">Total Blindness</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h7">15:05</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="body1">tribute to</WhiteTextBoldTypography>
                <WhiteTextBoldTypography variant="h4">AC/DC</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h6">16:55</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h6">Once</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h8">18:45</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h5">The H of Horse</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h7">20:35</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h4">Frituur Paula</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h6">22:25</WhiteTextNormalTypography>
              </div>
              <ColoredLine color="white" />
              <div className="TextBundler">
                <WhiteTextBoldTypography variant="h6">'015 KID' Music Mix</WhiteTextBoldTypography>
                <WhiteTextNormalTypography variant="h8">00:00 - 02:00</WhiteTextNormalTypography>
              </div>
            </div>
          </div>
          <Link to="/" className="RouterButton">
            <RouterButton variant="contained">Homepage</RouterButton>
          </Link>
        </div>
      </div>
    );
  }
}
