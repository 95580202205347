import "./App.css";
import Home from "./pages/Home";
import Lineup from "./pages/Lineup";
import Info from "./pages/Info";
import TermsAndConditions from "./pages/TermsAndConditions";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { BlackTextNormalTypography } from "./components/StyledMUIComponents";
import ScrollToTop from "./components/ScrollToTop";
import ZeroStress from "./pages/ZeroStress";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <div className="AppContent">
          <Route path="/" exact render={(props) => <Home {...props} />} />
          <Route path="/line-up" exact render={(props) => <Lineup {...props} />} />
          <Route path="/info" exact render={(props) => <Info {...props} />} />
          <Route path="/terms-and-conditions" exact render={(props) => <TermsAndConditions {...props} />} />
          <Route path="/zero-stress" exact render={(props) => <ZeroStress {...props} />} />
        </div>
      </Router>
      <div className="Footer">
        <div className="EmjeLogo" />
        <div className="GroupDaedalusLogo" />
        <div className="FlandriaRentLogo" />
        <div className="WimVerhuurLogo" />
      </div>
    </div>
  );
}

export default App;
