import "./Info.css";
import React from "react";
import AppHeader from "../components/AppHeader";
import { WhiteTextNormalTypography, WhiteTextBoldTypography, BlackTextNormalTypography, BlackTextBoldTypography, RouterButton } from "../components/StyledMUIComponents";
import { Link } from "react-router-dom";

export default class Info extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <AppHeader />
        <div className="Info">
          <div className="InfoBlock">
            <div className="TextWrapper">
              <WhiteTextBoldTypography variant="h5">Plaats en datum</WhiteTextBoldTypography>
            </div>
            <WhiteTextNormalTypography variant="h5">Zondag 14 EN maandag 15 aug ‘22</WhiteTextNormalTypography>
            <WhiteTextNormalTypography variant="h5">Bosstraat 35 (achter garage Wuyts)</WhiteTextNormalTypography>
            <div className="TextWrapper">
              <WhiteTextBoldTypography variant="h5">Te bereiken</WhiteTextBoldTypography>
            </div>
            <WhiteTextNormalTypography variant="h5">• Met de bus (lijnen 561, 510, 511)</WhiteTextNormalTypography>
            <WhiteTextNormalTypography variant="h5">• Met de auto (gratis aan kleuterschooltje Bosstraat 36 op 300m)</WhiteTextNormalTypography>
            <WhiteTextNormalTypography variant="h5">• Met de fiets (stalling op de festivalweide)</WhiteTextNormalTypography>
            <div className="TextWrapper">
              <WhiteTextBoldTypography variant="h5">Inbegrepen in de prijs</WhiteTextBoldTypography>
            </div>
            <WhiteTextNormalTypography variant="h5">
              • Plaatsje om een tent te zetten voor de nachten van 14 en 15 aug
            </WhiteTextNormalTypography>
            <WhiteTextNormalTypography variant="h5">• Zomerse topbeleving in het landelijke O.L.Vr.Waver</WhiteTextNormalTypography>
            <div className="TextWrapper">
              <WhiteTextBoldTypography variant="h5">Food & drinks</WhiteTextBoldTypography>
            </div>
            <WhiteTextNormalTypography variant="h5">Foodtrucks en cafetaria aanbod ; democratische prijzen</WhiteTextNormalTypography>
            <WhiteTextNormalTypography variant="h5">(graag eigen drank en food thuis laten aub)</WhiteTextNormalTypography>
            <div className="TextWrapper">
              <WhiteTextBoldTypography variant="h5">COVID-proof organisatie</WhiteTextBoldTypography>
            </div>
            <WhiteTextNormalTypography variant="h5">Voor vragen kan je altijd hier terecht: <a href="mailto:info@halfoogstfestival.be">info@halfoogstfestival.be</a></WhiteTextNormalTypography>
            <div className="TextWrapper">
              <WhiteTextBoldTypography variant="h5">ZERO-stress arrangement</WhiteTextBoldTypography>
            </div>
            <WhiteTextBoldTypography variant="h5">Beperkt aanbod</WhiteTextBoldTypography>
            <WhiteTextNormalTypography variant="h5">Beleef ons Half Oogst Midsummer Music festival in de allerbeste omstandigheden, samen met enkele vrienden of collega's</WhiteTextNormalTypography>
            <WhiteTextNormalTypography variant="h5"><Link to="/zero-stress">Klik hier voor meer informatie</Link></WhiteTextNormalTypography>
          </div>
          <div className="TextBundler">
            <div className="Paddinated">
              <Link to="/" className="RouterButton">
                <RouterButton variant="contained">Homepage</RouterButton>
              </Link>
            </div>
            <div className="Paddinated">
              <Link to="/terms-and-conditions" className="RouterButton">
                <RouterButton variant="contained">Algemene Voorwaarden</RouterButton>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
