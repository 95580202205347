import "./AppHeader.css";
import React from "react";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";

export default function AppHeader() {
  return (
    <AppBar className="AppHeader">
      <Toolbar>
        <Link to="/">
          <div className="AppHeaderFullLogo">
            <div className="AppHeaderWhiteLogo" />
            <div className="AppHeaderTagline" />
          </div>
        </Link>
        <div className="NavigationButtons">
          <Link to="/line-up" className="NavigationButton">
            Programma
          </Link>
          <Link to={{ pathname: "https://tickets.halfoogstfestival.be/" }} target="_blank" className="NavigationButton">
            Tickets
          </Link>
          <Link to="/info" className="NavigationButton">
            Info
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  );
}
