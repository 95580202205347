import "./Home.css";
import React from "react";
import { WhiteTextNormalTypography, WhiteTextBoldTypography, BlackTextNormalTypography, BlackTextBoldTypography, RouterButton } from "../components/StyledMUIComponents";
import { Link } from "react-router-dom";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="Home">
          <div className="WhiteLogo" />
          <div className="Tagline" />
          <div className="TextWrapper">
            <WhiteTextBoldTypography variant="h4">ZONDAG 14 EN MAANDAG 15 AUG. 2022</WhiteTextBoldTypography>
          </div>
          <div className="TextWrapper">
            <WhiteTextNormalTypography variant="h5">Bosstraat 35 • 2861 O.L.V.Waver</WhiteTextNormalTypography>
          </div>
          <div className="TextWrapper">
            <WhiteTextBoldTypography variant="h5">1 dag: €12,- per persoon</WhiteTextBoldTypography>
          </div>
          <div className="TextWrapper">
            <WhiteTextBoldTypography variant="h5">COMBI: €20,- per persoon</WhiteTextBoldTypography>
          </div>
          <div className="TextWrapper">
            <WhiteTextNormalTypography variant="h6">HALF OOGST Festival - midsummer music is een feel-good rockfestival met coverbands, tribute bands, look-alikes & regionale talenten. Dit alles badend in de zwoele augustuswarmte, tussen de weilanden, hartje O.L.V.Waver, en met hoog vakantiegehalte</WhiteTextNormalTypography>
          </div>
        </div>
        <div className="BottomBar">
          <Link to="/line-up" className="HomeRouterButton">
            <RouterButton variant="contained">Programma</RouterButton>
          </Link>
          <Link to={{ pathname: "https://tickets.halfoogstfestival.be/" }} target="_blank" className="HomeRouterButton">
            <RouterButton variant="contained">Tickets</RouterButton>
          </Link>
          <Link to="/info" className="HomeRouterButton">
            <RouterButton variant="contained">Info</RouterButton>
          </Link>
        </div>
      </div>
    );
  }
}
