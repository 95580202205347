import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const WhiteTextNormalTypography = withStyles({
  root: {
    color: "#FFFFFF",
    fontFamily: "Otomanopee One, sans-serif;",
  },
})(Typography);

const WhiteTextBoldTypography = withStyles({
  root: {
    color: "#FFFFFF",
    fontFamily: "Otomanopee One, sans-serif;",
    fontWeight: "bold",
  },
})(Typography);

const BlackTextNormalTypography = withStyles({
  root: {
    color: "#000000",
    fontFamily: "Otomanopee One, sans-serif;",
  },
})(Typography);

const BlackTextBoldTypography = withStyles({
  root: {
    color: "#000000",
    fontFamily: "Otomanopee One, sans-serif;",
    fontWeight: "bold",
  },
})(Typography);

const RouterButton = withStyles({
  root: {
    color: "rgba(241,133,0,1)",
    fontWeight: "bold",
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "10%",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.2)",
    },
  },
})(Button);

const PayButton = withStyles({
  root: {
    color: "rgba(20,255,20,1)",
    fontWeight: "bold",
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "10%",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.2)",
    },
  },
})(Button);

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: color,
      backgroundColor: color,
      height: 0.2,
      width: "50%",
    }}
  />
);

export { WhiteTextNormalTypography, WhiteTextBoldTypography, BlackTextNormalTypography, BlackTextBoldTypography, RouterButton, PayButton, ColoredLine };
